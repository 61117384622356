




























import {Component, Mixins, Prop, PropSync, Ref, Vue} from "vue-property-decorator"
import UserView from "@/views/UserView.vue"
import User from "@/models/User"
import {getModule} from "vuex-module-decorators"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import UserService from "@/services/UserService"
import LangModule from "@/store/LangModule"
import Handler from "@/handlers/Handler";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import CommercialService from "@/services/user/CommercialService";
import SnackbarMixin from "@/mixins/SnackbarMixin";

@Component
export default class CommercialTab extends Mixins(SnackbarMixin) {
    lang: any = getModule(LangModule).lang

    @Ref() readonly form!: HTMLFormElement
    @PropSync('commercial') syncedCommercial!: SingleItem<User>

    loading: boolean = false

    updateCommercial() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.updateUserConfirmation,
                async () => {
                    try {
                        await Handler.getItem(this, this.syncedCommercial, () =>
                            CommercialService.patchCommercial(this.syncedCommercial.item.id!, this.syncedCommercial.item)
                        )
                        this.makeToast("Los datos del comercial se han actualizado")
                    } catch (e) { console.log(e) }
                }
            ))


        }
    }
}
