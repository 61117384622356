












































































import {Component, Prop, Ref, Vue} from "vue-property-decorator"
import UserView from "@/views/UserView.vue"
import User from "@/models/User"
import {getModule} from "vuex-module-decorators"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import UserService from "@/services/UserService"
import LangModule from "@/store/LangModule"
import Options from "@/models/vue/Options"
import OrderService from "@/services/OrderService"
import Order from "@/models/Order"
import SessionModule from "@/store/SessionModule";
import OrderStatusComponent from "@/components/OrderStatusComponent.vue";

@Component({components: { OrderStatusComponent }})
export default class OrderTableComponent extends Vue {

	lang: any = getModule(LangModule).lang
	@Prop() readonly user!: User
	options: Options = new Options()
	orders: Order[] = []
	sessionModule: SessionModule = getModule(SessionModule)
	isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]

	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0
	search: string = ""

	headers = [
		{ align: "center", text: this.lang.date, value: "createdAt", 			width: "auto" },
		{ align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto" },
		{ align: "center", text: this.lang.status, value: "status", 			width: "auto" },
		{ align: "center", text: "Xgest ID", value: "connectorId", 				width: "auto" },
	]

	created() {
		this.refresh()
	}

	async refresh() {
		await OrderService.getMyOrders(
				this, this.orders, this.page - 1, this.itemsPerPage, Number(this.$route.params.id),
				this.search, null, null, null, null
		)
	}


}
