




























































































































































import CommercialClientsTab from "@/components/commercial/CommercialClientsTab.vue";
import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import User from "@/models/User"
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import UserService from "@/services/UserService";
import RouterTool from "@/services/tool/RouterTool";
import CommercialTab from "@/components/tabs/CommercialTab.vue"
import OrdersTab from "@/components/tabs/OrdersTab.vue";
import LangModule from "@/store/LangModule";
import OrderTableComponent from "@/components/OrderTableComponent.vue";
import ConfigModule from "@/store/ConfigModule";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import CommercialService from "@/services/user/CommercialService";
import ClientService from "@/services/ClientService";
import SnackbarModule from "@/store/SnackbarModule";

@Component({components: {CommercialClientsTab, OrdersTab, CommercialTab, OrderTableComponent}})
export default class CommercialView extends Vue {

  get configs() { return getModule(ConfigModule).configs }
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	tabs: string[] = ["/orders"]
	tab: number = 0
	loading: boolean = false
	commercial: User = new User()
	changePasswordDialog: boolean = false
	password: string = ""
	newPassword: string = ""
	repeatPassword: string = ""
	showPassword: boolean = false
	showNewPassword: boolean = false

	commercial2: SingleItem<User> = { item: new User() }

	newPasswordRules = [
		(v: string) => v.length > 0 ? true : this.lang.newPasswordRequired,
		(v: string) => v.length >= 8 ? true : this.lang.password8CharactersRequired
	]

	deactivateCrm() { UserService.deactivateMyCommercialCrm(this, this.commercial.id!!) }

	activateCrm() { UserService.activateMyCommercialCrm(this, this.commercial.id!!) }

	created() {
		this.tab = RouterTool.configTabs(this, this.tabs)
		this.refresh()
	}

	async refresh() {

		await Handler.getItem(this, this.commercial2, () =>
			CommercialService.getCommercial(Number(this.$route.params.id))
		)

		UserService.getMyCommercial(this, Number(this.$route.params.id))
	}

	@Watch("tab")
	watchTab() {
		RouterTool.watchTabs(this, "/commercials/" + Number(this.$route.params.id), this.tab, this.tabs)
	}

	changePassword() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning, this.lang.passwordUpdateQuestion,
				() => UserService.patchChangePassword(this, this.password, this.newPassword, Number(this.$route.params.userId))
			))
		}
	}

	openChangePasswordDialog() {
		if (this.form) this.form.resetValidation()
		this.changePasswordDialog = true
		this.password = ""
		this.newPassword = ""
		this.repeatPassword = ""
		this.showPassword = false
		this.showNewPassword = false
	}

	deleteCommercial() {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, this.lang.deleteUserConfirmation,
			async () => {
				await Handler.getItem(this, {item: null}, () =>
					CommercialService.deleteCommercial(Number(this.$route.params.id))
				)
				await this.$router.push('/commercials')
			}))
	}

	setActive() {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, "¿Esta seguro de cambiar el estado del cliente?",
			async () => await Handler.getItem(this, this.commercial2, () =>
				CommercialService.setActive(this.commercial2.item.id!, !this.commercial2.item.active!)
			)
		))
	}

}
